<template>
  <div>
    <!-- <the-navigation /> -->
    <div class="container" style="max-width: 100%">
      <div class="row">
        <!-- Mini Box -->
        <div class="col-12 col-md-4">
          <div class="mini-box">
            <template v-for="(val, idx) in summary.cashAtBank">
              <div class="mini-box-header" :key="idx">
                {{ val.description }}
              </div>
              <div class="mini-box-content" :key="val.description">
                {{ numberWithCommas(val.total) }}
              </div>
            </template>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="mini-box">
            <div class="mini-box-header">Accounts Receivable (HKD)</div>
            <div class="mini-box-content">{{ summary.accountsReceivable }}</div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="mini-box">
            <div class="mini-box-header">Since Established</div>
            <div class="mini-box-content">
              + {{ summary.sinceEstablished }} Days
            </div>
          </div>
        </div>

        <div class="w-100"></div>
        <!-- Promo Sales -->
        <div class="col-12 col-md-9">
          <div class="chart-box">
            <h3>Revenue (HKD)</h3>
            <div>
              <bar-chart :chart-data="revenueDataSet"></bar-chart>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div>
            <template>
              <v-data-table
                :headers="promoSalesEarnTable.headers"
                :items="promoSalesEarnTable.records"
              ></v-data-table>
            </template>
          </div>
        </div>
        <div class="w-100"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import TheNavigation from '@/components/TheNavigation'
// Chart
import BarChart from '@/components/BarChart'
// import LineChart from "@/components/LineChart";
// import PieChart from "@/components/PieChart";
import Vuetify from 'vuetify'
import request from '@/utils/request'

export default {
  vuetify: new Vuetify(),
  components: {
    // TheNavigation,
    BarChart
    // LineChart,
    // PieChart,
  },
  data() {
    return {
      summary: {
        sinceEstablished: '',
        cashAtBank: [],
        accountsReceivable: ''
      },

      revenueDataSet: {},
      promoSalesEarnTable: {
        records: [
          {
            month: 'Jan',
            currentYear: 121.45,
            lastYear: 161.9,
            percent: '5%'
          },
          {
            month: 'Feb',
            currentYear: 164.22,
            lastYear: 134,
            percent: '12%'
          },
          {
            month: 'Mar',
            currentYear: 2.78,
            lastYear: 57,
            percent: '8%'
          },
          {
            month: 'Apr',
            currentYear: 2.78,
            lastYear: 57,
            percent: '8%'
          },
          {
            month: 'May',
            currentYear: 2.78,
            lastYear: 57,
            percent: '8%'
          },
          {
            month: 'Jun',
            currentYear: 2.78,
            lastYear: 57,
            percent: '8%'
          },
          {
            month: 'Jul',
            currentYear: 2.78,
            lastYear: 57,
            percent: '8%'
          },
          {
            month: 'Aug',
            currentYear: 2.78,
            lastYear: 57,
            percent: '8%'
          }
        ],
        headers: [
          {
            text: 'Month',
            value: 'month',
            align: 'left'
          },
          { text: 'Promo Sales - 2020', value: 'currentYear', align: 'left' },
          { text: 'Promo Sales - 2019', value: 'lastYear', align: 'left' },
          { text: '%', value: 'percent', align: 'left' }
        ]
      }
    }
  },
  mounted() {
    this.loadDashboard()
  },
  methods: {
    async loadDashboard() {
      const { data } = await request({
        url: '/?t=loadDashboard',
        // url: "//localhost:8080/loadDashboard",
        data: { data: '' }
      })

      if (data.statusCode == 200) {
        this.summary.sinceEstablished = data.sinceEstablished
        this.summary.cashAtBank = data.cashAtBank
        this.summary.accountsReceivable = this.numberWithCommas(
          data.accountsReceivable
        )
        // Revenue
        this.revenueDataSet = {
          labels: [
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
            'Jan',
            'Feb',
            'Mar'
          ],
          datasets: [
            {
              label: ['Current Year'],
              borderWidth: 1,
              backgroundColor: 'rgba(54, 162, 235, 0.6)',
              borderColor: 'rgba(54, 162, 235, 1)',
              data: data.revenue.currentYear
            },
            {
              label: ['Last Year'],
              borderWidth: 1,
              backgroundColor: 'rgba(153, 102, 255, 0.6)',
              borderColor: 'rgba(153, 102, 255, 1)',
              data: data.revenue.lastYear
            }
          ]
        }
      }
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}
</script>

<style scoped>
.lineChartDiv {
  min-width: 200px;
  width: 50%;
}

.mini-box {
  border: 1px solid orange;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 4px 5px #ffedcd;
}

.mini-box-header {
  text-align: left;
  font-weight: bold;
}

.mini-box-content {
  text-align: right;
  font-weight: bold;
  font-size: 22pt;
}

.chart-box {
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 4px 5px gainsboro;
}
</style>
